import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const url="https://restdev.ciptatapaksejahtera.co.id"
const url1='https://rest.ciptatapaksejahtera.co.id'
const url2='https://restdev.ciptatapaksejahtera.co.id'

window.$name = 'king'
window.$ImageBaseUrl = url
window.$login=url+'/Auth/Authenticate'
window.$changePass=url+'/Auth/ChangePassword'
window.$brandAdd=url+'/Brand/add'
window.$brandIndex=url+'/Brand/Index'
window.$brandUpdate=url+'/Brand/update'
window.$brandDelete=url+'/Brand/Delete'
window.$brandFind=url+'/Brand/find'
window.$brandFindById=url+'/Brand/FindById'
window.$brandImageFindById=url+'/Brand/ImageFindById'
window.$brandIndexImage=url+'/Brand/indexImage'
window.$brandUploadImage=url+'/Brand/UploadImage'
window.$brandDelImage=url+'/Brand/deleteimage'
window.$customerIndex=url+'/OurCustomer/Index'
window.$customerFindById=url+'/OurCustomer/findById'
window.$customerDelete=url+'/OurCustomer/Delete'
window.$customerUpdate=url+'/OurCustomer/update'
window.$customerAdd=url+'/OurCustomer/add'
window.$customerUpload=url+'/OurCustomer/UploadLogo'
window.$contentIndex=url+'/Contents/Index'
window.$contentIndexCompact=url+'/Contents/indexcompact'
window.$contentAdd=url+'/Contents/add'
window.$contentUpdate=url+'/contents/update'
window.$contentUpdateByName=url+'/contents/updatebyname'
window.$contentFindByName=url+'/contents/findByName'
window.$contentFindById=url+'/contents/findById'
window.$contentDelete=url+'/contents/Delete'
window.$contentUploadImg=url+'/contents/UploadImg'
window.$picmapIndex=url+'/PicMap/Index'
window.$picmapAdd=url+'/PicMap/add'
window.$picmapUpdate=url+'/PicMap/update'
window.$picmapDelete=url+'/PicMap/Delete'
window.$picmapUploadImg=url+'/PicMap/UploadImg'
window.$picmapFindById=url+'/PicMap/findById'
window.$picmapFindByName=url+'/PicMap/findByName'
window.$picmapAddItem=url+'/PicMap/AddItem'
window.$picmapListImage=url+'/PicMap/ListImage'
window.$picmapUpdateItem=url+'/PicMap/UpdateItem'
window.$picmapDeleteItem=url+'/PicMap/DeleteItem'
window.$eventIndex=url+'/OurEvent/Index'
window.$eventAdd=url+'/OurEvent/add'
window.$eventUpdate=url+'/OurEvent/update'
window.$eventDelete=url+'/OurEvent/Delete'
window.$eventDelImage=url+'/OurEvent/deleteimage'
window.$eventFindById=url+'/OurEvent/findById'
window.$eventUploadImage=url+'/OurEvent/UploadImage'
window.$eventAddVideo=url+'/OurEvent/addVideo'
window.$eventDelVideo=url+'/OurEvent/deleteVideo'
window.$storyIndex=url+'/OurStory/Index'
window.$storyFindById=url+'/OurStory/findById'
window.$storyUploadImage=url+'/OurStory/UploadImage'
window.$storyAddVideo=url+'/OurStory/addVideo'
window.$storyDelVideo=url+'/OurStory/deleteVideo'
window.$storyAdd=url+'/OurStory/add'
window.$storyUpdate=url+'/OurStory/update'
window.$storyDelete=url+'/OurStory/Delete'
window.$storyDelImage=url+'/OurStory/deleteimage'
window.$webIndex=url+'/WebMessage/index'
window.$webDelete=url+'/WebMessage/delete'
window.$webFindById=url+'/WebMessage/findById'
window.$productIndex=url+'/Product/index'
window.$productAdd=url+'/Product/add'
window.$productFindById=url+'/Product/FindById'
window.$productDelete=url+'/Product/delete'
window.$productUpdate=url+'/Product/update'
window.$productFind=url+'/Product/find'
window.$productIndexImage=url+'/Product/indexImage'
window.$productImageFindById=url+'/Product/ImageFindById'
window.$productUploadImage=url+'/Product/UploadImage'
window.$productDelImage=url+'/Product/deleteimage'

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
